//ContentForm.tsx

import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";

import Spinner from "../../../../Components/Spinner/LoadingSpinner";

import ForgotPassword from "./ForgotPassword";
import Form from "./Form";

const ContentForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const ToggleForgotPassword = useCallback(() => {
    setForgotPassword((prevState) => !prevState);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        padding: { xs: 2, md: 4 },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            minWidth: { xs: "320px", md: "360px" },
            minHeight: "590px",
            border: "5px solid",
            borderImage: "linear-gradient(45deg, #aaa, #999) 1",
            zIndex: 10,
            background: "rgb(0,0,0,0.7)",
            backdropFilter: "blur(30px)",
            WebkitBackdropFilter: "blur(30px)",
            position: "relative",
          }}
        >
          {forgotPassword ? (
            <ForgotPassword
              FPfunction={ToggleForgotPassword}
              FPvariable={forgotPassword}
            />
          ) : (
            <Form
              setLoading={setLoading}
              ToggleForgotPassword={ToggleForgotPassword}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ContentForm;
