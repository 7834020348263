import { Box } from "@mui/material";
import React from "react";

import Socials from "./Socials";
import ConnectWithUs from "./ConnectWithUs";

const BottomBar: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent background for glass effect
        backdropFilter: "blur(10px)", // Blur effect for glass appearance
        backgroundSize: "cover",
        boxShadow:
          "rgba(0, 0, 0, 0.09) 0px -2px 1px, rgba(0, 0, 0, 0.09) 0px -4px 2px, rgba(0, 0, 0, 0.09) 0px -8px 4px, rgba(0, 0, 0, 0.09) 0px -16px 8px, rgba(0, 0, 0, 0.09) 0px -32px 16px;", // New box shadow
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          gap: "0.5rem",
          maxWidth: "1400px",
          width: "100%",
          margin: "0 auto",
          boxSizing: "border-box", // Ensure padding is within the width
          overflow: "hidden", // Prevent any overflow
        }}
      >
        <Socials />
        <ConnectWithUs />
      </Box>
      <Box
        sx={{
          height: "0.5vh",
          width: "100%",
          background:
            "linear-gradient(90deg, #5c1f5c, #d16f84, #f46f52, #f6c94b, #3fb39d)",
          backgroundSize: "cover",
        }}
      />
    </Box>
  );
};

export default BottomBar;
