import React from "react";
import "./App.css";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginPage from "./Pages/Login/LoginPage";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import EmailVerification from "./Pages/EmailVerification/EmailVerification";
import { darkTheme } from "./Theme/Theme";

function App(): JSX.Element {
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route key={"LoginMain"} path="/" element={<LoginPage />} />
          <Route
            key={"Password Reset"}
            path="/passwordreset/:token"
            element={<ResetPassword />}
          />
          <Route
            key={"Verified Page"}
            path="/account-confirm-email/:token"
            element={<EmailVerification />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
