import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Local components and services
import { PasswordReset } from "../../Services/authServices";
import Toast from "../../Components/Toast/Toast";

const ResetPassword = () => {
  // Use useParams to get uid and token from the URL
  const token = useParams<{ token: string }>().token || ""; // Default to an empty string

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [_error, setError] = useState("");

  const [checkPassword, setCheckPassword] = useState(false);
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const _validatePassword = useCallback(() => {
    const PASSWORD_REGEX =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return PASSWORD_REGEX.test(password);
  }, [password]);

  useEffect(() => {
    setCheckPassword(!_validatePassword() && password.length > 0);
    setCheckConfirm(password !== confirm && confirm.length > 0);
  }, [password, confirm, _validatePassword]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (checkPassword || checkConfirm) {
      if (checkPassword) {
        toast(
          <Toast
            message="Password must be at least 8 characters long and contain one uppercase letter, one lowercase letter, one number, and one special character."
            intent="fail"
          />,
        );
      }
      if (checkConfirm) {
        toast(<Toast message="Passwords do not match." intent="fail" />);
      }
      return;
    }

    try {
      // Call the PasswordReset function
      const response = await PasswordReset(token, password, confirm);

      if (response.status === 200) {
        toast(<Toast message="An unknown error occurred." intent="fail" />);
      }
    } catch (error: unknown) {
      let errorMessage = "Something went wrong. Please try again.";

      // Check if the error is an Axios error and extract the message
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data.detail || errorMessage;
      }

      setError(errorMessage);
    }
  };

  return (
    <Box
      width={"100vw"}
      height={"100dvh"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Paper
        elevation={10}
        sx={{
          maxWidth: 400,
          mx: "auto",
          mt: 8,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "1px solid #ccc",
          borderRadius: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" my={2} gutterBottom>
            Reset Your Password
          </Typography>
          <Stack gap={2}>
            <TextField
              label="Password"
              variant="outlined"
              color="info"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              error={checkPassword && password.length > 0}
              helperText={
                checkPassword && password.length > 0 ? "Weak password" : ""
              }
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              color="info"
              type={showPassword ? "text" : "password"}
              value={confirm}
              onChange={(e) => setConfirm(e.target.value)}
              fullWidth
              error={checkConfirm && confirm.length > 0}
              helperText={
                checkConfirm && confirm.length > 0
                  ? "Passwords do not match"
                  : ""
              }
              required
            />
          </Stack>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ my: 4 }}
          >
            Reset Password
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default ResetPassword;
