// authService.ts
import axios, { AxiosError, AxiosResponse, isAxiosError } from "axios";

const targetIP = `https://qa-auth.faircloud.ai/`;

export const login = async (
  email: string,
  password: string,
): Promise<Response> => {
  try {
    const response = await axios.post(
      `${targetIP}authentication/v1/login`,
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // If you need to send cookies with the request
      },
    );

    const { access, refresh } = response.data;

    if (access) {
      // Store the JWT token (e.g., in localStorage or secure cookie)
      localStorage.setItem("access_token", access);
      if (refresh) {
        localStorage.setItem("refresh_token", refresh);
      }
    }

    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const errorMessage =
        error.response?.data.non_field_errors?.[0] ||
        "Something went wrong. Please try again later.";
      throw new Error(errorMessage);
    } else {
      throw new Error("Network error. Please try again later.");
    }
  }
};

export const signUp = async (
  email: string,
  password1: string,
  password2: string,
  name: string,
): Promise<Response> => {
  try {
    const response = await axios.post(`${targetIP}authentication/v1/register`, {
      name,
      email,
      password1,
      password2,
    });

    return response.data;
  } catch (error: unknown) {
    let errorMessage = "An unknown error occurred.";

    if (isAxiosError(error)) {
      if (error.response?.data) {
        let serverError = error.response?.data.detail;
        if (error.response?.status == 500) {
          serverError =
            "Your Account has been created, but verification email failed to send. Please verify manually by sending the verification email from settings.";
        }
        errorMessage =
          serverError || error.response.data.email[0] || errorMessage;
      } else {
        errorMessage =
          error.message || "Network Error. Please try again later.";
      }
    }
    throw new Error(errorMessage);
  }
};

export const checkSignIn = async (): Promise<Response> => {
  const response = await fetch(`${targetIP}authentication/check_login/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    mode: "cors",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const data = await response.json();
  return data;
};

export const isUserLoggedIn = (): boolean => {
  const accessToken = localStorage.getItem("access_token");
  // Optionally you can add more checks (like expiration of token)
  return accessToken !== null;
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem("access_token");
};

export const logout = (): void => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};

export const VerifyEmail = async (email: string): Promise<Response> => {
  const targetURL = `${targetIP}authentication/v1/password/reset/`;

  try {
    const response = await axios.post(
      targetURL,
      {
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Include credentials if needed
      },
    );
    return response.data.status;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      let serverError = error.response?.data.detail;
      if (error.response?.status == 500) {
        serverError =
          "Mail could not be sent due to server issues. Please try again later.";
      }
      const errorMessage =
        serverError ||
        error.response?.data.email[0] ||
        "Something went wrong. Please try again later.";
      throw new Error(errorMessage);
    } else {
      throw new Error("Something went wrong. Please try again later.");
    }
  }
};

export const PasswordReset = async (
  token: string,
  password1: string,
  password2: string,
): Promise<AxiosResponse> => {
  // Use AxiosResponse type for clarity
  const response = await axios.post(
    `${targetIP}authentication/v1/password/reset/confirm/`,
    {
      token,
      password1,
      password2,
    },
  );

  return response; // Return the response
};
