import {
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { startTransition } from "react";
import { useNavigate } from "react-router-dom";

import { darkTheme } from "../../Theme/Theme";

const EmailVerification: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    startTransition(() => {
      navigate(path);
    });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box>
        <Typography>Welcome</Typography>
        <Typography>
          Your account has been verified. Please login again to start your
          savings
        </Typography>
        <Button onClick={() => handleNavigate("/")}>Go to Login Page</Button>
      </Box>
    </ThemeProvider>
  );
};

export default EmailVerification;
