import React, { useState, useCallback, lazy, Suspense } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";

import GoogleLoginButton from "../../../../Components/Login/SocialLogin/GoogleLoginButton";
import Toast from "../../../../Components/Toast/Toast";
import { login, signUp } from "../../../../Services/authServices";
import ErrorMessage from "../../../../Components/Login/ErrorMessage";

// Lazy load the LoginForm and SignUpForm components
const LoginForm = lazy(() => import("../../../../Components/Login/LoginForm"));
const SignUpForm = lazy(
  () => import("../../../../Components/Login/SignUpForm"),
);

interface FormContainerProps {
  setLoading: (value: boolean) => void;
  ToggleForgotPassword: () => void;
}

const Form: React.FC<FormContainerProps> = (props: FormContainerProps) => {
  const [error, setError] = useState<string>("");
  const [showLogin, setShowLogin] = useState(true);

  const MSGLoginSucessful = "Login Successful";
  const MSGSignupSucessful = "Signup complete. Login to continue";

  const handleSuccessLoginRedirection = useCallback(() => {
    // handleLoginSuccess();
  }, []);

  const handleLogin = async (userInput: string, passwordInput: string) => {
    props.setLoading(true);
    setError("");
    try {
      await login(userInput, passwordInput);
      toast(<Toast message={MSGLoginSucessful} intent="success" />);
      setShowLogin(true);
      handleSuccessLoginRedirection();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        toast(<Toast message={error.message} intent="fail" />);
      } else {
        setError("An unknown error occurred.");
        toast(<Toast message="An unknown error occurred." intent="fail" />);
      }
    } finally {
      props.setLoading(false);
    }
  };

  const handleSignUp = async (
    emailInput: string,
    passwordInput: string,
    confirmInput: string,
    fullnameInput: string,
  ) => {
    props.setLoading(true);
    setError("");
    try {
      await signUp(emailInput, passwordInput, confirmInput, fullnameInput);
      toast(<Toast message={MSGSignupSucessful} intent="success" />);
      setShowLogin(true);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        toast(<Toast message={error.message} intent="fail" />);
      } else {
        setError("An unknown error occurred.");
        toast(<Toast message="An unknown error occurred." intent="fail" />);
      }
    } finally {
      props.setLoading(false);
    }
  };

  const ToggleForgotPassword = useCallback(() => {
    props.ToggleForgotPassword();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{
        minHeight: "inherit",
        minWidth: "inherit",
      }}
    >
      {error && <ErrorMessage message={error} />}
      <GoogleLoginButton />
      <Suspense fallback={<div>Loading...</div>}>
        {showLogin ? (
          <LoginForm onSubmit={handleLogin} />
        ) : (
          <SignUpForm onSubmit={handleSignUp} />
        )}
      </Suspense>
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant="text"
          sx={{ width: "120px" }}
          onClick={() => setShowLogin((prev) => !prev)}
        >
          <Typography variant="button" textTransform="capitalize">
            {showLogin ? "Sign Up" : "Back to Login"}
          </Typography>
        </Button>
        <Divider
          orientation="vertical"
          sx={{ height: 40, borderColor: "#0004" }}
        />
        <Button variant="text" onClick={ToggleForgotPassword}>
          <Typography variant="button" textTransform="capitalize">
            Forgot Password
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
