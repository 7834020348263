import React from "react";
import { Button, Stack, Typography } from "@mui/material";

import { ReactComponent as GoogleLogo } from "./google-logo.svg"; // Make sure to have the Google logo SVG file

interface GoogleLoginButtonProps {
  onClick?: () => void;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        m: "2em 0",
        background: "#ccc",
        color: "#000",
        width: "auto",
        "&:hover": { backgroundColor: "#aaa" },
      }}
    >
      <Stack direction={"row"} useFlexGap spacing={2}>
        <GoogleLogo />
        <Typography>Login with Google</Typography>
      </Stack>
    </Button>
  );
};

export default GoogleLoginButton;
