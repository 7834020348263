import React from "react";
import { Box } from "@mui/material";

import Content from "./Content/Content";
import Logo from "./TopBar/Logo";
import BottomBar from "./BottomBar/BottomBar";

const LoginPage: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#000000",
      }}
    >
      <Box
        sx={{
          width: "100vw",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          background: `
          radial-gradient(
            ellipse at top left,
            rgba(50, 50, 50, 0.5) 0%,
            rgba(70, 70, 70, 0.5) 40%,
            rgba(90, 90, 90, 0.5) 60%,
            rgba(110, 110, 110, 0.3) 80%,
            rgba(130, 130, 130, 0.2) 100%
          ),
          radial-gradient(
            ellipse at bottom right,
            rgba(130, 130, 130, 0.5) 0%,
            rgba(110, 110, 110, 0.3) 30%,
            rgba(90, 90, 90, 0.2) 50%,
            rgba(70, 70, 70, 0.1) 70%,
            rgba(50, 50, 50, 0.05) 100%
          ),
          linear-gradient(
            90deg,
            rgba(50, 50, 50, 0.3),
            rgba(70, 70, 70, 0.3),
            rgba(90, 90, 90, 0.3),
            rgba(110, 110, 110, 0.3),
            rgba(130, 130, 130, 0.3)
          )
        `,
          backgroundBlendMode: "overlay",
          backdropFilter: "blur(30px)", // Reduced for performance
          color: "#f0f0f0",
        }}
      >
        <Box
          sx={
            {
              // backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent background for glass effect
              // backdropFilter: "blur(10px)", // Blur effect for glass appearance
              // backgroundSize: "cover",
              // boxShadow:
              //   "rgba(0, 0, 0, 0.09) 0px -2px 1px, rgba(0, 0, 0, 0.09) 0px -4px 2px, rgba(0, 0, 0, 0.09) 0px -8px 4px, rgba(0, 0, 0, 0.09) 0px -16px 8px, rgba(0, 0, 0, 0.09) 0px -32px 16px;", // New box shadow
            }
          }
        >
          <Logo />
        </Box>
        <Box
          sx={{
            padding: 4,
          }}
        >
          <Content />
        </Box>
        <Box sx={{ width: "100%", marginTop: "auto" }}>
          <BottomBar />
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
