import { Box, Typography } from "@mui/material";
import React from "react";

import TermsModal from "./TermsModal";
import PrivacyModal from "./PrivacyModal";

const ConnectWithUs: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative", // Make position relative for the label
        gap: 2,
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: 400,
          color: "#f0f0f0",
          textAlign: "center",
          textDecoration: "none", // Remove underline
        }}
      >
        Have Questions? Connect with us at{" "}
        <Typography
          component="a" // Make the Typography component an anchor element
          href="mailto:support@faircloud.ai" // Add the mailto link
          sx={{
            fontFamily: "Space Grotesk, sans-serif",
            fontWeight: 400,
            color: "#f0f0f0",
            textAlign: "center",
            textDecoration: "none", // Remove underline
            cursor: "pointer", // Change the cursor to indicate clickable text
            "&:hover": {
              transform: "scale(1.1)",
              color: "#ffffff",
            },
          }}
        >
          support@faircloud.ai
        </Typography>
      </Typography>

      <Box sx={{ display: "flex", gap: 2 }}>
        <PrivacyModal />
        <TermsModal />
      </Box>
    </Box>
  );
};

export default ConnectWithUs;
