// ErrorMessage.tsx
import React from "react";
import { Box } from "@mui/material";

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <Box
      sx={{
        color: "#dc3545",
        fontSize: "14px",
        marginTop: "10px",
      }}
    >
      {message}
    </Box>
  );
};

export default ErrorMessage;
