import React from "react";
import { Box, Typography } from "@mui/material";
import {
  IoAccessibilityOutline,
  IoFlashOutline,
  IoTimeOutline,
} from "react-icons/io5";

interface Feature {
  icon: React.ElementType;
  title: string;
  description: string;
  color: string;
}

const features: Feature[] = [
  {
    icon: IoTimeOutline,
    title: "No downtime",
    description:
      "FairCloud AI will never access or modify any of your instances.",
    color: "#005374",
  },
  {
    icon: IoAccessibilityOutline,
    title: "Limited-access IAM Role",
    description:
      "FairCloud AI only requests a light IAM policy to access your CPU and network utilization.",
    color: "#6CB295",
  },
  {
    icon: IoFlashOutline,
    title: "Lightning fast",
    description:
      "Setup takes fewer than 5 minutes and can be completed without ever taking a meeting.",
    color: "#F2B11B",
  },
];

const LoginDescription: React.FC = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: { xs: 2, md: 4 },
        gap: 4,
      }}
    >
      <Typography
        sx={{
          fontFamily: "IBM Plex Sans, sans-serif",
          fontWeight: 400,
          fontSize: { xs: "2rem", md: "2.5rem" },
        }}
        variant="h2"
      >
        Reduce your AWS bill by <span className="gradient-text">57%</span> in
        the next <span className="gradient-text">5 mins</span>.
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        {features.map(({ icon: Icon, title, description, color }, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              margin: { xs: 2, md: 4 },
            }}
          >
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "3rem",
              }}
            >
              <Icon size="2rem" color={color} aria-label={`${title} icon`} />
            </Box>

            <Box>
              <Typography
                sx={{
                  fontFamily: "IBM Plex Sans, sans-serif",
                  fontWeight: 400,
                  fontSize: { xs: "1.25rem", md: "1.5rem" },
                }}
                variant="h4"
              >
                {title}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "IBM Plex Sans, sans-serif",
                  fontWeight: 400,
                  fontSize: { xs: "1rem", md: "1.25rem" },
                  color: "text.secondary",
                }}
              >
                {description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LoginDescription;
