import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TermsModal: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        component="a"
        onClick={handleOpen}
        sx={{
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: 400,
          color: "#f0f0f0",
          textAlign: "center",
          textDecoration: "none",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
            color: "#ffffff",
          },
        }}
      >
        Terms of Use
      </Typography>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        sx={{ background: "transparent" }}
      >
        <DialogContent
          dividers
          sx={{
            bgcolor: "#333",
            color: "#f0f0f0",
            padding: { xs: "0 2px", sm: "0 2rem" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 999,
              bgcolor: "#333",
              padding: "1rem",
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIcon />}
              sx={{
                borderColor: "#f0f0f0",
                color: "#f0f0f0",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                  color: "#282c34",
                },
                "&:focus": {
                  outline: "none",
                  boxShadow: "none",
                },
                "&:focusVisible": {
                  outline: "none",
                  boxShadow: "none",
                },
                "&.MuiButton-outlined": {
                  "&:hover": {
                    borderColor: "#f0f0f0",
                  },
                },
              }}
            >
              Back
            </Button>
          </Box>
          <Box padding={{ xs: 2, sm: 3 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
            >
              Terms of Use
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Effective Date: October 20, 2024
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              These Terms and Conditions (“Terms”) constitute a legally binding
              agreement between VelvetVector Tech Labs LLP (“VelvetVector,”
              “we,” “us,” or “our”) and the users (“you” or “your”) who access,
              interact with, or use our website and services. By using or
              accessing our website and services, you acknowledge that you have
              read, understood, and agreed to be bound by these Terms.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              1. The Website and Services
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Our website provides information regarding our business, platform,
              services, and other related content, including but not limited to
              contact details, multimedia content (such as images and videos),
              logos, and other specialized features (“Content”). It also offers
              channels through which users can communicate with us for various
              purposes such as inquiries about our services, scheduling
              demonstrations, applying for jobs, or accessing our blog and other
              resources. Collectively, these offerings, along with the website,
              content, and any freemium or demo services, are referred to as the
              “Services.”
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We reserve the right to modify, suspend, or discontinue any aspect
              of the Services, Content, or website at any time without prior
              notice or liability. The Content provided is intended for
              informational and business purposes only and should not be
              construed as professional advice. Any reliance on the information
              provided through our website is done at your own risk, and
              VelvetVector expressly disclaims any liability for actions or
              omissions taken as a result of your use of the Content.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              2. Freemium Services
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We offer certain features of our Services free of charge
              (“Freemium Services”). To access these services, you will need to
              create an account. The information provided during registration is
              governed by our Privacy Policy. VelvetVector may update or modify
              its subscription plans from time to time, and we encourage users
              to periodically review the subscription options provided on our
              website.
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Upon account creation, you represent and warrant that you are
              responsible for all activities conducted through your account. You
              agree not to share your login credentials with unauthorized
              individuals and to promptly notify VelvetVector of any
              unauthorized use or access to your account.
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              VelvetVector grants you a limited, non-exclusive,
              non-transferable, non-sublicensable license to access and use the
              Freemium Services and any deliverables generated through them,
              such as reports or analytics, subject to these Terms. VelvetVector
              reserves the right to suspend or terminate accounts in case of any
              violation of these Terms or applicable laws.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              3. Demo Services
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              VelvetVector offers free 30-minute demo sessions (“Demo Services”)
              with a company expert, during which no payment details are
              required. The Demo Services are purely for consultation purposes,
              and you are not obligated to purchase any further services. Any
              information shared during or after the demo session will be
              handled in accordance with our Privacy Policy.
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              VelvetVector grants you a non-exclusive, non-transferable license
              to access and use the Demo Services and any resulting
              deliverables, subject to these Terms. We reserve the right to
              modify or terminate the Demo Services at any time, at our sole
              discretion.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              4. User Representations and Restrictions
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              By accessing our Services, you represent and warrant that:
            </Typography>
            <ul>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  You have the legal authority to enter into these Terms.
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  You are not a minor in your jurisdiction or below the age of
                  13.
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  You will comply with all applicable laws, including
                  intellectual property laws, when using the website or
                  Services.
                </Typography>
              </li>
            </ul>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Furthermore, you agree not to:
            </Typography>
            <ul>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  Use the Services in a manner that is illegal, fraudulent, or
                  otherwise unauthorized.
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  Interfere with security features or access the Services using
                  automated means (such as bots or scrapers).
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  Copy, modify, or create derivative works of the Services.
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  Reverse engineer or attempt to extract the source code of any
                  software components of the Services.
                </Typography>
              </li>
              <li>
                <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                  Remove or alter any proprietary notices, including copyright
                  and trademark information.
                </Typography>
              </li>
            </ul>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              5. Intellectual Property
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              All Content and deliverables, including those from Demo Services
              and Freemium Services, are the exclusive property of VelvetVector
              or its licensors. No license, right, title, or interest in the
              Content or deliverables is transferred to you unless explicitly
              stated. You are prohibited from using VelvetVector’s intellectual
              property without prior written consent, except as expressly
              permitted under these Terms.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              6. Disclaimer of Warranties
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              The Services, Content, and deliverables are provided on an “as is”
              and “as available” basis, without any warranties, express or
              implied, including but not limited to warranties of
              merchantability, fitness for a particular purpose, or
              non-infringement. VelvetVector disclaims liability for any actions
              or decisions made based on the information provided through the
              Services. We do not warrant that the Services will be
              uninterrupted or error-free, or that the information will be
              complete or current.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              7. Limitation of Liability
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              To the fullest extent permitted by law, VelvetVector, its
              affiliates, and their respective officers, employees, and agents
              shall not be liable for any indirect, incidental, or consequential
              damages, including but not limited to lost profits, loss of data,
              or business interruptions arising out of your use of the Services.
              In no event shall VelvetVector’s total liability exceed the amount
              of INR 750 (approximately USD 10).
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              8. Indemnification
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              You agree to indemnify and hold harmless VelvetVector and its
              affiliates from any third-party claims, damages, or expenses
              arising from your use of the Services in violation of these Terms
              or applicable law. This indemnification obligation will survive
              the termination of these Terms.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              9. Termination
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              You may terminate these Terms at any time by discontinuing your
              use of the Services. VelvetVector reserves the right to terminate
              your access to the Services, with or without cause, at any time
              and without notice.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              10. Governing Law and Jurisdiction
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              These Terms shall be governed by and construed in accordance with
              the laws of India. Any disputes arising from these Terms or your
              use of the Services shall be subject to the exclusive jurisdiction
              of the courts in Mumbai, India.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              11. Miscellaneous
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              These Terms constitute the entire agreement between you and
              VelvetVector regarding the use of the Services. Any provision
              deemed unenforceable shall be modified to the minimum extent
              necessary to make it enforceable, without affecting the validity
              of the remaining provisions. Failure by VelvetVector to enforce
              any rights or take action in the event of a breach shall not be
              construed as a waiver of those rights. You may not assign these
              Terms without VelvetVector’s prior written consent.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              12. Amendments
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We reserve the right to modify or update these Terms at any time,
              and such changes will be effective upon posting on our website.
              Your continued use of the Services after any amendments indicates
              your acceptance of the revised Terms.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TermsModal;
