import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { GitHub, LinkedIn, X } from "@mui/icons-material";

const Socials: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "center", sm: "flex-start" }, // Center on small screens,
        textAlign: { xs: "left" },
        flexWrap: "wrap",
        gap: "1rem",
        paddingTop: "0.5rem",
      }}
    >
      <Tooltip title="GitHub" arrow>
        <IconButton
          href="https://github.com/FairCloudAI"
          target="_blank"
          sx={{
            color: "#f0f0f0",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "scale(1.2)",
              color: "#2b3137", // Custom hover color (blue)
              border: "1px solid #ffffff",
            },
            border: "1px solid #f0f0f0",
          }}
        >
          <GitHub />
        </IconButton>
      </Tooltip>
      <Tooltip title="LinkedIn" arrow>
        <IconButton
          href="https://in.linkedin.com/company/faircloudai"
          target="_blank"
          sx={{
            color: "#f0f0f0",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "scale(1.2)",
              color: "#0a66c2", // Custom hover color (blue)
              border: "1px solid #ffffff",
            },
            border: "1px solid #f0f0f0",
          }}
        >
          <LinkedIn />
        </IconButton>
      </Tooltip>
      <Tooltip title="X" arrow>
        <IconButton
          href="https://x.com/faircloudai"
          target="_blank"
          sx={{
            color: "#f0f0f0",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "scale(1.2)",
              color: "#000000", // Custom hover color (blue)
              border: "1px solid #ffffff",
            },
            border: "1px solid #f0f0f0",
          }}
        >
          <X />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default Socials;
