import React from "react";
import { Box } from "@mui/material";

import ContentDescription from "./ContentDescription/ContentDescription";
import ContentForm from "./ContentForm/ContentForm";

const LoginPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column-reverse", md: "row" },
        gap: 2,
        width: "100%",
        margin: "0 auto",
        maxWidth: "1200px",
        alignItems: "center",
      }}
    >
      <Box flex={1}>
        <ContentDescription />
      </Box>
      <Box flex={1}>
        <ContentForm />
      </Box>
    </Box>
  );
};

export default LoginPage;
