import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";

import "./toastStyles.scss";

interface Props {
  message: string;
  intent?: string;
}

const Toast: React.FC<Props> = (props) => {
  const intent = props.intent == "fail" ? "fail" : "success";

  React.useEffect(() => {
    const timer = setTimeout(() => {
      toast.dismiss();
    }, 500000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex items-center">
      <div>
        {intent == "fail" ? (
          <FaCircleXmark color="#e46a59" size={30} />
        ) : (
          <FaCircleCheck color="#27ae60" size={30} />
        )}
      </div>
      <div className="ml-4">{props.message}</div>
    </div>
  );
};

export default Toast;
