import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PrivacyPolicyModal: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        component="a"
        onClick={handleOpen}
        sx={{
          fontFamily: "Space Grotesk, sans-serif",
          fontWeight: 400,
          color: "#f0f0f0",
          textAlign: "center",
          textDecoration: "none",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
            color: "#ffffff",
          },
        }}
      >
        Privacy Policy
      </Typography>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        sx={{ background: "transparent" }}
      >
        <DialogContent
          dividers
          sx={{
            bgcolor: "#333",
            color: "#f0f0f0",
            padding: { xs: "0 2px", sm: "0 2rem" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 999,
              bgcolor: "#333",
              padding: "1rem",
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIcon />}
              sx={{
                borderColor: "#f0f0f0",
                color: "#f0f0f0",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                  color: "#282c34",
                },
                "&:focus": {
                  outline: "none",
                  boxShadow: "none",
                },
                "&:focusVisible": {
                  outline: "none",
                  boxShadow: "none",
                },
                "&.MuiButton-outlined": {
                  "&:hover": {
                    borderColor: "#f0f0f0",
                  },
                },
              }}
            >
              Back
            </Button>
          </Box>
          <Box padding={{ xs: 2, sm: 3 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
            >
              Privacy Policy for Candidate Data
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              Effective Date: October 20, 2024
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              VelvetVector Tech Labs LLP (“VelvetVector,” “we,” “us,” or “our”)
              is committed to protecting the privacy and security of the
              personal information of candidates who apply for positions with
              us. This Privacy Policy explains how we collect, use, disclose,
              and safeguard candidate data in connection with our recruitment
              processes. By submitting your application or any personal
              information to us, you agree to the terms outlined in this policy.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              1. Information We Collect
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              When you apply for a position at VelvetVector, we may collect the
              following types of personal information:
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              - Personal Identification Information: Name, email address, phone
              number, mailing address, and any other contact information you
              provide.
              <br />
              - Resume and Application Details: Information contained in your
              resume, cover letter, and any other materials you submit as part
              of your application (e.g., work history, education,
              qualifications).
              <br />
              - Interview Information: Notes and assessments made during
              interviews, as well as any other relevant information collected
              during the recruitment process.
              <br />- Assessment Results: Results from any pre-employment
              assessments or tests you may take as part of the application
              process.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              2. How We Use Your Information
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We may use the information we collect from you for the following
              purposes:
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              - Recruitment Process: To evaluate your qualifications and
              suitability for employment with VelvetVector, to communicate with
              you about your application, and to schedule interviews.
              <br />
              - Compliance and Record-Keeping: To maintain records related to
              the recruitment process for compliance with legal obligations and
              to improve our hiring processes.
              <br />- Talent Pool: To consider you for future job openings,
              unless you opt-out of this use.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              3. Legal Basis for Processing Your Data
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We process your personal data based on the following legal
              grounds:
              <br />
              - Consent: By submitting your application, you consent to our
              collection and use of your data as outlined in this policy.
              <br />- Legitimate Interests: We process your data to evaluate and
              recruit candidates for our positions, which we consider a
              legitimate interest.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              4. Sharing Your Information
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We do not sell, rent, or lease your personal information to third
              parties. We may share your information in the following
              circumstances:
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              - Service Providers: We may share your information with
              third-party service providers who assist us in the recruitment
              process. These parties are bound by confidentiality agreements.
              <br />- Legal Obligations: We may disclose your information if
              required to do so by law or in response to valid requests by
              public authorities.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              5. Data Retention
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We retain your personal information only for as long as necessary
              to fulfill the purposes outlined in this policy, comply with legal
              obligations, resolve disputes, and enforce our agreements. If you
              are not selected for a position, we may retain your information
              for a reasonable period for future recruitment considerations.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              6. Data Security
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We implement reasonable administrative, technical, and physical
              security measures to protect your personal information from
              unauthorized access, use, or disclosure. However, no method of
              transmission over the Internet is 100% secure.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              7. Your Rights
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              You have certain rights regarding your personal information,
              including:
              <br />
              - Access: You have the right to request access to the personal
              information we hold about you.
              <br />
              - Correction: You can request that we correct any inaccuracies in
              your personal data.
              <br />
              - Deletion: You may request the deletion of your personal
              information, subject to certain exceptions.
              <br />- Objection: You can object to the processing of your
              personal information in certain circumstances.
            </Typography>

            <Divider sx={{ bgcolor: "#FFFFFF", mb: 2 }} />

            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
            >
              8. Changes to This Privacy Policy
            </Typography>
            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              We may update this Privacy Policy from time to time. If we make
              significant changes, we will notify you by posting the new policy
              on our website. We encourage you to review this policy
              periodically for any updates.
            </Typography>

            <Typography
              paragraph
              sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}
            >
              If you have any questions or concerns about this Privacy Policy or
              our practices regarding your personal information, please contact
              us at: careers@faircloud.ai.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrivacyPolicyModal;
