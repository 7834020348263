import React from "react";
import { Box, Typography } from "@mui/material";

import logo from "./Assets/logo_white.svg";
const Logo: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "1400px",
        padding: 2,
        margin: "0 auto",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "2rem",
          height: "auto",
        }}
      >
        <img src={logo} alt="FairCloud AI Logo" />
      </Box>
      <Box ml={1.5}>
        <Typography
          variant="h5"
          fontFamily={"Ubuntu Mono, monospace"}
          fontWeight={200}
        >
          FairCloud {<span style={{ fontWeight: "bold" }}>AI</span>}
        </Typography>
      </Box>
    </Box>
  );
};

export default Logo;
