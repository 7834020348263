import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";

import Toast from "../../../../Components/Toast/Toast";
import { VerifyEmail } from "../../../../Services/authServices";

interface ForgotPasswordProps {
  FPfunction: () => void;
  FPvariable: boolean;
}

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ FPfunction }) => {
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);

  const validateEmail = useCallback(() => EMAIL_REGEX.test(email), [email]);

  // Email validation
  useEffect(() => {
    if (email === "") {
      setShowValidationError(false); // Don't show error on empty input
      return;
    }

    const isValid = validateEmail();
    setIsEmailInvalid(!isValid);
    setShowValidationError(true);
  }, [email, validateEmail]);

  const resetPassword = async (email: string) => {
    try {
      await VerifyEmail(email);
      toast(
        <Toast
          message={"Email successfully sent. Please check your email."}
          intent="success"
        />,
      );
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "An unknown error has occurred.";
      toast(<Toast message={errorMessage} intent="fail" />);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isEmailInvalid) {
      toast(<Toast message="Invalid email address." intent="fail" />);
      return;
    }

    await resetPassword(email);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{
        minHeight: "inherit",
        minWidth: "inherit",
      }}
    >
      <form
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "2rem",
        }}
        onSubmit={handleSubmit}
      >
        <Stack width={"80%"} sx={{ zIndex: 4 }}>
          <Typography variant="h5" my={3} textAlign="center">
            Forgot Password
          </Typography>
          <TextField
            label="Email Address"
            variant="outlined"
            color="info"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={showValidationError && isEmailInvalid}
            helperText={
              showValidationError && isEmailInvalid
                ? "Invalid email address."
                : ""
            }
            margin="normal"
            fullWidth
            required
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="success"
            sx={{ my: 3 }}
          >
            Send Recovery Link
          </Button>
          <Button variant="text" size="large" onClick={FPfunction}>
            Go Back
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default ForgotPassword;
